$.trumbowyg.svgPath = '/assets/trumbowyg_icons.svg';

window.popper = require('@popperjs/core');
window.tempusdominus = require('@eonasdan/tempus-dominus/dist/js/tempus-dominus.min');

const fa_five = require('@eonasdan/tempus-dominus/dist/plugins/fa-five');

tempusdominus.extend(fa_five);

window.textEditor = function (v) {
    $(v).trumbowyg({
        lang: 'de',
        defaultLinkTarget: '_blank',
        svgAbsoluteUsePath: true,
        btns: [
            ['undo', 'redo'], // Only supported in Blink browsers
            ['fontfamily'],
            ['lineheight'],
            ['formatting'],
            ['strong', 'em', 'del'],
            ['foreColor', 'backColor'],
            ['superscript', 'subscript'],
            ['link'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['emoji'],
            ['horizontalRule'],
            ['removeformat'],
            ['table'],
            [/*'viewHTML',*/'fullscreen']
        ],
        plugins: {
            colors: {
                colorList: [
                    'c41215', 'fecc00', '000', 'fff'
                ]
            }
        }
    });
}
